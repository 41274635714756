import React from 'react';
import PropTypes from 'prop-types';

const FormattedHTMLMessage = ({ tagName, message, className, onLinkClick }) => {
  const handleLinkClick = (e) => {
    if (e.target.nodeName === 'A' && !!onLinkClick) {
      onLinkClick(e);
    }
  };

  return React.createElement(tagName, {
    className,
    dangerouslySetInnerHTML: {
      __html: message
    },
    onClick: handleLinkClick
  });
};

FormattedHTMLMessage.propTypes = {
  message: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  className: PropTypes.string,
  onLinkClick: PropTypes.func
};

FormattedHTMLMessage.defaultProps = {
  tagName: 'span',
  className: null,
  onLinkClick: null
};

export default FormattedHTMLMessage;
